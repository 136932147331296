<template>
  <section class="pt-4 px-0 px-xl-4">
    <b-container fluid>
      <b-row>
        <b-col class="mb-3" cols="4">
          <b-form-select
            class="text-center font-weight-bold"
            size="lg"
            v-model="printServerIndex"
          >
            <b-form-select-option
              v-for="(name, value) in printServerList"
              :key="value"
              :value="value"
              >{{ name }}</b-form-select-option
            >
          </b-form-select>
        </b-col>
        <b-col class="mb-3" cols="2" v-if="printServerIndex !== null">
          <b-form-select
            class="text-center font-weight-bold"
            size="lg"
            v-model="workStationNumber"
            :options="workStationNumberOptions"
          ></b-form-select>
        </b-col>
        <b-col class="mb-3" cols="3" v-if="printServerIndex !== null"
          ><b-button @click="save" size="lg" variant="primary"
            >Aggiorna <font-awesome-icon icon="sync"/></b-button
        ></b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "WorkStationConfiguration",
  data() {
    return {
      workStationNumber: null,
      workStationNumberOptions: null,
      printerConfig: null,
      printServerIndex: null,
      printServerList: null
    };
  },
  watch: {
    printServerIndex: function() {
      this.setWorkStationList();
    }
  },
  mounted() {
    this.get();
    this.workStationNumber = localStorage.getItem("workStationNumber");
  },
  methods: {
    save: function() {
      localStorage.setItem("printServerIndex", this.printServerIndex);
      localStorage.setItem("workStationNumber", this.workStationNumber);
      this.$store.state.workStationNumber = this.workStationNumber;
      this.$router.go(-1);
    },
    setPrintServerList: function() {
      let result = [];
      this.printerConfig.forEach(function(element) {
        result.push(element.location);
      });
      this.printServerList = result;
      if (this.printServerList.length === 1) this.printServerIndex = 0;
    },
    setWorkStationList: function() {
      let result = [];
      this.printerConfig[this.printServerIndex].config.forEach(function(
        element
      ) {
        result.push(element.workStationNumber);
      });
      this.workStationNumberOptions = result.filter(
        (v, i, a) => a.indexOf(v) === i
      );
    },
    get: function() {
      this.$http
        .get(`${process.env.VUE_APP_API_ENDPOINT}config/printer/get`)
        .then(res => {
          this.printerConfig = res.data;
          this.setPrintServerList();
        })
        .catch(err => {
          this.error = err;
        });
    }
  }
};
</script>
